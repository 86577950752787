import { Component, Injector } from '@angular/core';

import { ModalBasePage } from '../base/modal-base.page';

@Component({
	selector: 'app-modal-location-permission',
	templateUrl: 'modal-location-permission.page.html',
	styleUrls: ['modal-location-permission.page.scss']
})
export class ModalLocationPermissionPage extends ModalBasePage {

	constructor(private injector: Injector) {
		super(injector);
	}

	ok(): void {
		this.modalCtrl.dismiss(true);
	}
}
