import { isPlatform } from '@ionic/angular';
import { VersionCompare } from '../enums/version-compare.enum';

export class UtilHelper {

	public static getEnumByCod(code: any, obj: any): any {
		const key: string = Object.keys(obj).find((k: string) => {
			return obj[k].code === code;
		});
		return obj[key];
	}

	public static getEnumByDescription(description: string, obj: any): any {
		const key: string = Object.keys(obj).find((k: string) => {
			return obj[key].description === description;
		});
		return obj[key];
	}

	public static getIdFromLocationPath(location: string): string {
		let id: string = null;
		try {
			if (location && location.length > 0) {
				const respSplited: Array<string> = location.split("/");
				if (respSplited.length > 0) {
					id = respSplited[respSplited.length - 1];
				}
			}
		} catch (error) {
			console.log(error);
		}
		return id;
	}

	public static isNativeApp(): boolean {
		// if (document.URL.indexOf("appios://appfastget") === 0 || document.URL.indexOf("https://appfastget") === 0) {
		return isPlatform("cordova");
	}

	public static isWebViewFromAnotherApp(): boolean {
		if (isPlatform("pwa") || isPlatform("cordova")) {
			return false;	
		}
		let userAgent = window.navigator.userAgent.toLowerCase();
		let safari = /safari/.test(userAgent);
		if (userAgent.includes('wv') || (isPlatform("ios") && !safari)) {
			return true;
		}
		return false;
	}

	public static buildUrlParams(params: any): string {
		if (params == null)
			return null;

		const urlParams: URLSearchParams = new URLSearchParams();
		for (const key in params) {
			if (params[key] != null) {
				urlParams.append(key, params[key]);
			}
		}
		return urlParams.toString();
	}

	public static mask(v: string, mask: string): string {
		let s: string = '';

		const matches: RegExpMatchArray = String(v).match(/[a-zA-Z0-9]+/g);
		if (matches !== null) {
			if (mask == null) return;

			let value: Array<string> = matches.join('').split('');
			const chars: Array<string> = mask.split('');
			for (const c of chars) {
				if (value.length === 0) {
					break;
				}
				switch (c) {
					case '#': //qualquer
						s += value[0];
						value = value.slice(1);
						break;

					case '9':
						if (value[0].match(/\d/) !== null) {
							s += value[0];
							value = value.slice(1);
						}
						break;

					case 'A':
						if (value[0].match(/[a-zA-Z]/) !== null) {
							s += value[0];
							value = value.slice(1);
						}
						break;

					default:
						s += c;
				}
			}
			return s;
		}
	}

	public static unmask(value: string): string {
		if (!value) return "";
		return value.replace(/[^a-z0-9]/gi, "");
	}

	public static convertMoneyToNumber(value: string): number {
		if (!value) return value as any;
		return Number(value.replace(/[$.]/g,'').replace(/[$,]/g,'.'));
	}

	public static getRandomBgImage(): string {
		const now: Date = new Date();
		let folder: string = "";
		let image: number = UtilHelper.getRandomNumberBetween(1, 3);

		if (now.getHours() > 3 && now.getHours() < 11) {
			folder = "breakfast";
		} else if (now.getHours() >= 11 && now.getHours() < 15) {
			folder = "lunch";
		} else if (now.getHours() >= 15 && now.getHours() < 18) {
			//café da tarde contém 2 imagens apenas
			image = UtilHelper.getRandomNumberBetween(1, 2);
			folder = "coffee";
		} else if (now.getHours() >= 18 && now.getHours() < 20) {
			folder = "happyhour";
		} else {
			folder = "dinner";
		}
		return "assets/img/" + folder + "/" + image + ".jpg";
	}

	/**
	 * Obtém randomicamente um número entre o intervalor definido.
	 * @param initial
	 * @param last
	 */
	 public static getRandomNumberBetween(initial: number, last: number): number {
		return Math.floor(Math.random() * (last - initial + 1)) + initial;
	}

	// https://stackoverflow.com/a/21623206/6031927
	public static calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
		try {
			const p: number = 0.017453292519943295; // Math.PI / 180
			const cos: any = Math.cos;
			const a: number = 0.5 -
				cos((lat2 - lat1) * p) / 2 +
				cos(lat1 * p) * cos(lat2 * p) *
				(1 - cos((lon2 - lon1) * p)) / 2;

			return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
		} catch (error) {
			return null;
		}
	}

	/**
	 * Compara versões
	 * @param localVersion
	 * @param remoteVersion
	 */
	public static compareVersions(localVersion: string, remoteVersion: string): VersionCompare {
		if (localVersion === remoteVersion) {
			return VersionCompare.IDENTICAL_VERSIONS;
		}

		const local_components: Array<string> = localVersion.split(".");
		const remote_components: Array<string> = remoteVersion.split(".");

		const len: number = Math.min(local_components.length, remote_components.length);

		// loop while the components are equal
		for (let i: number = 0; i < len; i++) {

			// Local maior que remoto
			if (parseInt(local_components[i]) > parseInt(remote_components[i])) {
				return VersionCompare.LOCAL_VERSION_HIGHER;
			}

			// Remoto maior que local
			if (parseInt(local_components[i]) < parseInt(remote_components[i])) {
				return VersionCompare.REMOTE_VERSION_HIGHER;
			}
		}

		// If one's a prefix of the other, the longer one is greater.
		if (local_components.length > remote_components.length) {
			return VersionCompare.LOCAL_VERSION_HIGHER;
		}

		if (local_components.length < remote_components.length) {
			return VersionCompare.REMOTE_VERSION_HIGHER;
		}

		// Otherwise they are the same.
		return VersionCompare.IDENTICAL_VERSIONS;
	}

	/**
	 * Scroll horizontal.
	 * Adaptação do código https://gist.github.com/felipenmoura/650e7e1292c1e7638bcf6c9f9aeb9dd5
	 * @param to
	 * @param element
	 * @param duration
	 */
	public static scrollHorizontalTo(to: number, element: HTMLElement, duration: number = 500): Promise<void> {
		//t = current time
		//b = start value
		//c = change in value
		//d = duration
		const easeInOutQuad: any = (t, b, c, d) => {
			t /= d / 2;
			if (t < 1) return c / 2 * t * t + b;
			t--;
			return -c / 2 * (t * (t - 2) - 1) + b;
		};

		return new Promise((resolve) => {
			const start: number = element.scrollLeft,
				change: number = to - start,
				increment: number = 20;

			let currentTime: number = 0;

			const animateScroll: VoidFunction = () => {
				currentTime += increment;
				const val: any = easeInOutQuad(currentTime, start, change, duration);
				element.scrollLeft = val;
				if (currentTime < duration) {
					setTimeout(animateScroll, increment);
				} else {
					resolve();
				}
			};
			animateScroll();
		});
	}

	public static limitCharInput(input: any, maxlength: number): void {
		if (input.value.length > maxlength) {
			input.value = input.value.substr(0, maxlength);
		}
	}

	public static getIdCodeFromLink(link: string): { id: string, type: 'STORE' | 'PLACE' | 'TABLE' } {
		if (link.indexOf("/store/") > -1 || link.indexOf("/local/") > -1) {
			try {
				let key: string = "";
				const retorno: any = { id: "", type: "" };

				if (link.indexOf("/store/") > -1) {
					key = "/store/";
					retorno.type = "STORE";
				} else {
					key = "/local/";
					retorno.type = "PLACE";
				}
				const keySplited: Array<string> = link.split(key);
				console.log(keySplited);
				if (keySplited.length > 1) {
					// name/id
					const params: Array<string> = keySplited[1].split("/");
					if (params.length > 0) {
						const uuid: string = params[1];
						console.log(uuid);
						if (uuid) {
							retorno.id = uuid;
							return retorno;
						}
					}
				}
			} catch (error) {
				console.error(error);
			}
		} else if (link.indexOf("tableCode=") > -1 || link.indexOf("/table/") > -1) {
			try {
				const tableKey: string = link.indexOf("tableCode=") > -1 ? "tableCode=" : "/table/";
				const tableCodetSplited: Array<string> = link.split(tableKey);
				console.log(tableCodetSplited);
				if (tableCodetSplited.length > 1 && tableCodetSplited[1]) {
					return { id: tableCodetSplited[1], type: "TABLE" };
				}
			} catch (error) {
				console.error(error);
			}
		}
		return null;
	}

	public static getTradeNameToLinkFormat(name: string): string {
		let storeNameLink: string = name;
		try {
			storeNameLink = storeNameLink.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
			storeNameLink = storeNameLink.replace(/ /g, '-');
		} catch (error) {
			console.log(error);
		}
		return storeNameLink.toLowerCase();
	}

	public static getAllQueryParams(): Array<any> {
		const urlParameters: Array<{ name: string, value: string }> = [];
		if (document.URL.indexOf("?") > 0) {
			const splitURL: Array<string> = document.URL.split("?");
			const splitParams: Array<string> = splitURL[1].split("&");
			let i: any;
			for (i in splitParams) {
				if (splitParams[i] != null) {
					const singleURLParam: Array<string> = splitParams[i].split('=');
					const urlParameter: any = {
						name: singleURLParam[0],
						value: singleURLParam[1]
					};
					urlParameters.push(urlParameter);
				}
			}
		}
		return urlParameters;
	}

	public static getQueryParam(paramName: string): any {
		const param: any = this.getAllQueryParams().find((p) => {
			return p.name === paramName;
		});
		return param ? param.value : null;
	}

	public static async sleep(milisec: number): Promise<void> {
		return new Promise(resolve => setTimeout(resolve, milisec));
	}

	public static buildStarByRating(rating: number): Array<string> {
		if (rating == null || rating < 1) {
			return ["star-outline", "star-outline", "star-outline", "star-outline", "star-outline"];
		}
		if (rating === 1) {
			return ["star", "star-outline", "star-outline", "star-outline", "star-outline"];
		}
		if (rating < 2) {
			return ["star", "star-half", "star-outline", "star-outline", "star-outline"];
		}
		if (rating === 2) {
			return ["star", "star", "star-outline", "star-outline", "star-outline"];
		}
		if (rating < 3) {
			return ["star", "star", "star-half", "star-outline", "star-outline"];
		}
		if (rating === 3) {
			return ["star", "star", "star", "star-outline", "star-outline"];
		}
		if (rating < 4) {
			return ["star", "star", "star", "star-half", "star-outline"];
		}
		if (rating === 4) {
			return ["star", "star", "star", "star", "star-outline"];
		}
		if (rating < 5) {
			return ["star", "star", "star", "star", "star-half"];
		}
		if (rating >= 5) {
			return ["star", "star", "star", "star", "star"];
		}
	}
}
