import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { ModalNotificationPermissionPage } from './modal-notification-permission.page';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		TranslateModule.forChild()
	],
	declarations: [
		ModalNotificationPermissionPage
	]
})
export class ModalNotificationPermissionPageModule { }
