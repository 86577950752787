import { Component, Injector } from '@angular/core';

import { ModalBasePage } from '../base/modal-base.page';

@Component({
	selector: 'app-modal-notification-permission',
	templateUrl: 'modal-notification-permission.page.html',
	styleUrls: ['modal-notification-permission.page.scss']
})
export class ModalNotificationPermissionPage extends ModalBasePage {

	constructor(private injector: Injector) {
		super(injector);
	}

	ok(): void {
		try {
			window['plugins'].OneSignal.promptForPushNotificationsWithUserResponse((accepted) => {
				console.log("User accepted notifications: " + accepted);
				this.modalCtrl.dismiss(accepted);
			});
		} catch (erro) {
			this.modalCtrl.dismiss();
		}
	}
}
