import { PushType } from "../enums/push-type.enum";

export class Notification {

	private nativeNotification: NativeNotification;
	private webNotification: WebNotification;

	constructor(notification: NativeNotification | WebNotification, isNative: boolean) {
		if (isNative) {
			this.nativeNotification = notification as NativeNotification;
		} else {
			this.webNotification = notification as WebNotification;
		}
	}

	get title(): string {
		if (this.nativeNotification) {
			return this.nativeNotification.payload.title;
		} else {
			return this.webNotification.heading;
		}
	}

	get message(): string {
		if (this.nativeNotification) {
			return this.nativeNotification.payload.body;
		} else {
			return this.webNotification.content;
		}
	}

	get pushType(): PushType {
		if (!this.data || !this.data.pushType) {
			return null;
		}
		return this.data.pushType;
	}

	get data(): any {
		if (this.nativeNotification) {
			if (!this.nativeNotification.payload || !this.nativeNotification.payload.additionalData) {
				return null;
			}
			return this.nativeNotification.payload.additionalData;
		} else {
			return this.webNotification.data;
		}
	}

	get isNative(): boolean {
		return this.nativeNotification != null;
	}

	get isAppInFocus(): boolean {
		return this.nativeNotification && this.nativeNotification.isAppInFocus;
	}
}

export type NativeNotification = {
	androidNotificationId: number;
	displayType: number;
	isAppInFocus: boolean;
	payload: NotificationPayload;
	shown: boolean;
};

export type NotificationPayload = {
	notificationID: string;
	title: string;
	body: string;
	additionalData: any;
	lockScreenVisibility: number;
	priority: number;
};

export type WebNotification = {
	heading: string;
	content: string;
	data: any;
}
