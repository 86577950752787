import { Component, Injector } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { ActionOptionModalError } from 'src/app/enums/action-option-modal-error.enum';
import { ModalBasePage } from '../base/modal-base.page';


@Component({
	selector: 'app-modal-error-qrcode',
	templateUrl: 'modal-error-qrcode.page.html',
	styleUrls: ['modal-error-qrcode.page.scss']
})
export class ModalErrorQrcodePage extends ModalBasePage {

	constructor(
		private alertCtrl: AlertController,
		private platform: Platform,
		private injector: Injector
	) {
		super(injector);
	}

	readQrCode(): void {
		this.modalCtrl.dismiss({ option: ActionOptionModalError.READ_QRCODE_AGAIN });
	}

	async typeManually(): Promise<void> {
		// if (environment.production) {
			this.dismiss();
		// } else {
		// 	// DEV MODE
		// 	const alert: HTMLIonAlertElement = await this.alertCtrl.create({
		// 		header: 'Informe o código QR-Code',
		// 		inputs: [{
		// 			name: 'code',
		// 			placeholder: 'código',
		// 			type: 'tel'
		// 		}],
		// 		buttons: [{
		// 			text: 'Cancelar',
		// 			role: 'cancel',
		// 			cssClass: 'dark'
		// 		}, {
		// 			text: 'Buscar',
		// 			role: 'buscar',
		// 			handler: (table: any) => {
		// 				if (table.code) {
		// 					alert.dismiss();
		// 					this.modalCtrl.dismiss({
		// 						option: ActionOptionModalError.GET_ESTABLISHMENT_BY_CODE,
		// 						code: table.code
		// 					});
		// 				}
		// 				return false;
		// 			}
		// 		}]
		// 	});
		// 	alert.present();
		// }
	}
}
