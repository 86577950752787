import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

// tslint:disable-next-line: typedef
declare var gtag;
@Injectable({
	providedIn: 'root'
})
export class AnalyticsService {

	// http://meumobi.github.io/ionic/pwa/2018/04/12/using-analytics-ionic-app-pwa-native.html
	// https://stackoverflow.com/a/55222168/6031927

	constructor(private router: Router) { }

	init(): void {
		this.listenForRouteChanges();
		try {
			const script1: any = document.createElement('script');
			script1.async = true;
			script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gaId;
			document.head.appendChild(script1);

			const script2: any = document.createElement('script');
			script2.innerHTML = `
				window.dataLayer = window.dataLayer || [];
				function gtag(){dataLayer.push(arguments);}
				gtag('js', new Date());
				gtag('config', '` + environment.gaId + `', {'send_page_view': false});
			`;
			document.head.appendChild(script2);

			// JS para monitoramento
			// this.appendMonitoringJs();
		} catch (ex) {
			console.error('Error appending google analytics');
			console.error(ex);
		}
	}

	event(eventName: string, category: string, label?: string, value?: number): void {
		try {
			gtag('event', eventName, {
				event_category: category,
				event_label: label || "",
				value: value || 1
			});
		} catch (error) {
			console.error(error);
		}
	}

	exception(description: string): void {
		try {
			gtag('event', 'exception', {
				description: description,
				fatal: false
			});
		} catch (error) {
			console.error(error);
		}
	}

	trackView(path: string): void {
		try {
			// console.log(path);
			gtag('config', environment.gaId, {
				page_path: path
			});
		} catch (error) {
			console.error(error);
		}
	}

	private listenForRouteChanges(): void {
		try {
			this.router.events.subscribe(event => {
				if (event instanceof NavigationEnd) {
					this.trackView(event.urlAfterRedirects);
				}
			});
		} catch (error) {
			console.error(error);
		}
	}

	private appendMonitoringJs(): void {
		try {
			if (environment.production) {
				const scriptElem: any = document.createElement("script");
				scriptElem.type = "text/javascript";
				scriptElem.src = "assets/js/monitoring.js";
				document.head.appendChild(scriptElem);
			}
		} catch (error) {
			console.error(error);
		}
	}
}
