export class Country {
	code: string;
	name: string;
	flag: string;
	dial_code: string;

	constructor(obj: any = { }) {
		Object.assign(this, obj);
	}
}
