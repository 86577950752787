import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ModalSuccessfulPage } from './modal-successful.page';


@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		TranslateModule.forChild()
	],
	declarations: [
		ModalSuccessfulPage
	]
})
export class ModalSuccessfulPageModule { }
