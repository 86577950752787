import { HostListener, Injector, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

export class ModalBasePage implements OnInit, OnDestroy {

	protected modalCtrl: ModalController;

	constructor(injector: Injector) {
		this.modalCtrl = injector.get(ModalController);
	}

	ngOnInit(): void {
		const modalState: any = {
			modal: true,
			desc: 'modal-page'
		};
		history.pushState(modalState, null);
	}

	ngOnDestroy(): void {
		if (window.history.state.modal) {
			history.back();
		}
	}

	@HostListener('window:popstate', ['$event'])
	dismiss(): void {
		this.modalCtrl.dismiss();
	}
}
