import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
	selector: "app-modal-helper",
	templateUrl: "./modal-helper.page.html",
	styleUrls: ["./modal-helper.page.scss"]
})
export class ModalHelperPage implements OnInit {
	
	title: string;
	message: string;
	actionButtons: Array<{ id: number, title: string }> = null;
	showArrow: boolean;

	constructor(
		private modalCtrl: ModalController,
		private params: NavParams
	) {	}

	dismiss(): void {
		this.modalCtrl.dismiss();
	}

	acaoBotao(): void {
		this.modalCtrl.dismiss({ acao: true });
	}

	ngOnInit(): void {
		this.title = this.params.get("title") || "";
		this.message = this.params.get("message") || "";
		this.actionButtons = this.params.get("actionButtons") || [];
		this.showArrow = this.params.get("showArrow") || false;
	}

	callbackOption(option: any): void {
		this.modalCtrl.dismiss(option);
	}
}
