import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { ModalNotificationPermissionPage } from 'src/app/pages/modal-notification-permission/modal-notification-permission.page';

@Component({
	selector: 'app-modal-successful',
	templateUrl: 'modal-successful.page.html',
	styleUrls: ['modal-successful.page.scss'],
})
export class ModalSuccessfulPage implements OnInit, OnDestroy {

	type: "orders" | "reservation" | "register-user";
	title: string;
	icon: string;
	message: string;
	btnLabel: string;

	constructor(
		private modalCtrl: ModalController,
		private navParams: NavParams,
		private platform: Platform
	) { }

	ngOnInit(): void {
		const modalState: any = {
			modal: true,
			desc: 'modal-page'
		};
		history.pushState(modalState, null);

		this.type = this.navParams.get("type");
		this.icon = this.navParams.get("icon") || "checkmark-circle-outline";
		this.title = this.navParams.get("title");
		this.message = this.navParams.get("message");
		this.btnLabel = this.navParams.get("btnLabel") || "GENERAL.OK_GOT_IT";
	}

	ngOnDestroy(): void {
		if (window.history.state.modal) {
			history.back();
		}
	}

	@HostListener('window:popstate', ['$event'])
	dismiss(): void {
		try {
			if (this.type === "orders" && this.platform.is('cordova') && this.platform.is("ios")) {
				window['plugins'].OneSignal.getPermissionSubscriptionState(async (status) => {
					console.log(status);
					this.modalCtrl.dismiss();

					// iOS only: Integer: 0 = Not Determined, 1 = Denied, 2 = Authorized
					if (status.permissionStatus.status === 0) {
						const modal: HTMLIonModalElement = await this.modalCtrl.create({
							component: ModalNotificationPermissionPage,
							cssClass: 'custom-modal'
						});
						modal.present();
					}
				});
			} else {
				this.modalCtrl.dismiss();
			}
		} catch (error) {
			console.error(error);
			this.modalCtrl.dismiss();
		}
	}
}
