import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-maintenance',
	templateUrl: 'maintenance.page.html',
	styleUrls: ['maintenance.page.scss'],
})
export class MaintenancePage {

	constructor(private modalCtrl: ModalController) { }

	dismiss(): void {
		this.modalCtrl.dismiss();
	}

}
