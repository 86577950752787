import { Router } from "@angular/router";
import { NavController, ToastController } from "@ionic/angular";
import { AppEvents } from "../enums/app-events.enum";
import { PushType } from "../enums/push-type.enum";
import { Notification } from "../models/notification.model";
import { EventService } from "../services/event.service";

export class PushHelper {

	constructor(
		private eventService: EventService,
		private navCtrl: NavController,
		private router: Router,
		private toastController: ToastController
	) { }

	handlePush(notification: Notification, openedAction?: boolean): void {
		try {
			if (!notification.pushType) {
				return;
			}
			if (notification.isNative && openedAction) {
				window['plugins'].OneSignal.clearOneSignalNotifications();
			}
			let pathToGo: string;
			let detailPage: string;

			switch (notification.pushType) {
				case PushType.ORDERS:
					let orderId = notification.data.orderId;
					this.eventService.publish(AppEvents.ORDER_LIST_RELOAD);
					this.eventService.publish(AppEvents.ORDER_RELOAD, orderId);
					detailPage = "/order/";
					pathToGo = detailPage + orderId;
					break;
					
				case PushType.QUEUE:
					let queueId = notification.data.queueId;
					this.eventService.publish(AppEvents.QUEUE_RELOAD, queueId);
					detailPage = "/queue/";
					pathToGo = detailPage + queueId;
					break;

				default:
					return;
			}
			
			if (notification.isNative && openedAction) {
				this.navCtrl.navigateForward(pathToGo);
			} else if (!notification.isNative || notification.isAppInFocus) {
				const toastConfig: any = {
					message: notification.message,
					position: 'top',
					color: 'dark',
					duration: 5000,
					cssClass: 'toast-with-buttons',
				};
				this.showToastGoTo(toastConfig, notification.pushType, pathToGo, detailPage);
			}
		} catch (error) {
			console.error(error);
		}
	}

	private async showToastGoTo(toastConfig: any, pushType: PushType, pathToGo: string, detailPageToCompare: string): Promise<void> {
		// Se não estiver na página, mostra o botão "Ver" no toast para navegar até a página
		if (this.router.url.indexOf(detailPageToCompare) == -1) {
			toastConfig.buttons = [{
				text: 'Ver',
				handler: () => {
					switch (pushType) {
						case PushType.ORDERS:
							this.navCtrl.navigateForward(pathToGo);
							break;
							
						case PushType.QUEUE:
							this.navCtrl.navigateForward(pathToGo);
							break;
					}
				}
			}];
		}
		const toast: HTMLIonToastElement = await this.toastController.create(toastConfig);
		toast.present();
	}
}