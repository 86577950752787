import { LoginProvider } from '../enums/login-provider.enum';
import { UtilHelper } from '../helpers/utils.helper';
import { Address } from './address.model';

export class User {
	id: number;
	photoPath: string;
	name: string;
	username: string; //email
	cpf: string;
	phone: string;
	countryPhoneCode: string;
	addresses: Array<Address>;
	loginProvider: LoginProvider;
	firstLogin: boolean;
	isPublic: boolean;
	iuguCustomerId: string;
	password?: string; // usado no cadastro
	validPhone: boolean;

	constructor(user: any = { }) {
		Object.assign(this, user);

		if (!this.username) this.username = "";

		if (this.addresses && this.addresses.length > 0) {
			const adresses: Array<Address> = [];
			this.addresses.forEach((a: Address) => {
				adresses.push(new Address(a));
			});
			this.addresses = adresses;
		}
		if (this.phone) {
			if (!this.countryPhoneCode || this.countryPhoneCode == "+55") {
				this.phone = UtilHelper.mask(this.phone, this.phone.length > 10 ? "(99) 99999-9999" : "(99) 9999-9999");
			}
		}
	}

	getJsonToChangeData(): any {
		const data: any = {
			id: this.id,
			name: this.name,
			countryPhoneCode: this.countryPhoneCode,
			// username: this.username,
			cpf: this.cpf,
			phone: this.phone,
			firstLogin: this.firstLogin
		};
		return data;
	}

	getJsonToRegister(): any {
		const data: any = {
			name: this.name,
			username: this.username,
			cpf: this.cpf,
			phone: this.phone,
			countryPhoneCode: this.countryPhoneCode,
			firstLogin: false,
			password: this.password
		};
		return data;
	}
}
