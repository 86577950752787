import '@firebase/auth';

import { Injectable } from '@angular/core';
import firebase from '@firebase/app';
import { Facebook, FacebookLoginResponse } from '@ionic-native/facebook/ngx';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { isPlatform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import aes from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import { environment } from 'src/environments/environment';

import { APP_CONFIG, WEBSERVICE } from '../commom/constants';
import { EnvConfig } from '../commom/types';
import { AppEvents } from '../enums/app-events.enum';
import { AppleSignInScope } from '../enums/apple-signin-scope.enum';
import { LoginProvider } from '../enums/login-provider.enum';
import { UtilHelper } from '../helpers/utils.helper';
import { Country } from '../models/country.model';
import { ErrorApp } from '../models/error.model';
import { Filter } from '../models/filter.model';
import { LocalConfig } from '../models/local-config.model';
import { User } from '../models/user.model';
import { EventService } from './event.service';
import { RequestService } from './request.service';

declare var cordova: any;

@Injectable({
	providedIn: 'root'
})
export class UserService {

	private static readonly KEY: string = "RkBzdGczdEMwbnN1bTNyQHBwMjAxOSE=";

	envConfig: EnvConfig;

	user: User;
	localConfig: LocalConfig;
	filter: Filter;

	constructor(
		private eventService: EventService,
		private facebook: Facebook,
		private google: GooglePlus,
		private requestService: RequestService,
		private storage: Storage
	) {
		this.requestService.setUserService(this);
		this.initFirebase();
		this.filter = new Filter();
	}

	initFirebase(): void {
		// Initialize Firebase
		firebase.initializeApp(environment.firebaseConfig);
	}

	/**
	 * Retorna promisse com o usuário logado salvo no Storage.
	 */
	loadUser(forceUpdate?: boolean, userToken?: string): Promise<any> {
		return new Promise(async (resolve) => {
			if (this.user == null || forceUpdate || (userToken && this.user.isPublic)) {
				try {
					await this.loadLocalConfig();
					const token: string = await this.getToken("token");
					if (token) {
						this.requestService.setAuthToken(token);

						if (userToken) {
							await this.getUserAndValidateUserHash(userToken);
						} else {
							if (this.localConfig.lastUserIsPublic) {
								this.user = new User();
								this.user.isPublic = true;
								this.user.loginProvider = LoginProvider.OWN;
							} else {
								await this.getUserInfo();
							}
						}
					} else {
						//autentico o usuário público
						await this.loginForm(APP_CONFIG.PUBLIC_USER.USERNAME, APP_CONFIG.PUBLIC_USER.PASS, true);

						if (userToken) {
							await this.getUserAndValidateUserHash(userToken);
						}
					}
				} catch (error) {
					console.error(error);
				}
				resolve(this.user);
			} else {
				resolve(this.user);
			}
		});
	}

	/**
	 * Obtem o token ou refreshToken salvos de maneira segura
	 * @param key pode ser "token" ou "refreshToken"
	 */
	getToken(key: string): Promise<string> {
		return new Promise((resolve) => {
			// Usado na web ou como fallback caso falhar pelo plugin SecureKey
			const getTokenFromStorage: VoidFunction = async () => {
				let token: string = null;
				try {
					const tokenEncrypted: any = await this.storage.get(key);
					if (tokenEncrypted) {
						const bytes: any = aes.decrypt(tokenEncrypted, UserService.KEY);
						token = bytes.toString(Utf8);
					}
				} catch (erro) {
					console.error(erro);
				}
				resolve(token);
			};
			if (UtilHelper.isNativeApp()) {
				cordova.plugins.SecureKeyStore.get((token) => {
					resolve(token);
				}, (error) => {
					console.log(error);
					getTokenFromStorage();
				}, key);
			} else {
				getTokenFromStorage();
			}
		});
	}

	/**
	 * Salva o token ou refreshToken (ou qualquer outra informação importante) de maneira segura
	 * @param key pode ser "token" ou "refreshToken"
	 * @param token chave
	 */
	saveToken(key: string, token: string): Promise<void> {
		return new Promise((resolve) => {
			// Usado na web ou como fallback caso falhar pelo plugin SecureKey
			const setTokenToStorage: VoidFunction = () => {
				const tokenEncrypted: any = aes.encrypt(token, UserService.KEY).toString();
				this.storage.set(key, tokenEncrypted || token);
				resolve();
			};
			if (UtilHelper.isNativeApp()) {
				cordova.plugins.SecureKeyStore.set((res) => {
					resolve();
				}, (error) => {
					console.log(error);
					setTokenToStorage();
				}, key, token);
			} else {
				setTokenToStorage();
			}
		});
	}

	/**
	 * Remove o token ou refreshToken salvos de maneira segura
	 * @param key pode ser "token" ou "refreshToken"
	 */
	private removeToken(key: string): Promise<void> {
		return new Promise((resolve) => {
			// Usado na web ou como fallback caso falhar pelo plugin SecureKey
			const removeTokenFromStorage: VoidFunction = () => {
				this.storage.remove(key);
			};
			if (UtilHelper.isNativeApp()) {
				cordova.plugins.SecureKeyStore.remove((res) => {
					console.log(res);
					resolve();
				}, (error) => {
					console.log(error);
					removeTokenFromStorage();
				}, key);
			} else {
				removeTokenFromStorage();
			}
		});
	}

	async removeUser(): Promise<void> {
		this.user = null;
		this.storage.remove("user");
		this.requestService.setAuthToken(null);
		this.removeToken("token");
		this.removeToken("refreshToken");

		this.localConfig.lastUserIsPublic = true;
		await this.updateLocalConfig();
	}

	getUserInfo(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.requestService.getData(WEBSERVICE.USER_CLIENT).then((response: any) => {
				this.user = new User(response);
				this.user.isPublic = false;
				this.localConfig.lastUserIsPublic = false;
				this.updateLocalConfig();
				resolve();
			}, (error: ErrorApp) => {
				console.error(error);
				reject(error);
			});
		});
	}

	async getUserAndValidateUserHash(userHash: string): Promise<User> {
		try {
			const tokenResponse: any = await this.requestService.postData(WEBSERVICE.VALIDATE_USER_HASH, { userHash: userHash });
			if (tokenResponse && tokenResponse.access_token) {
				await this.finishLogin(tokenResponse);
			} else {
				// Caso não tenha nenhum usuário carregado, loga no user público para obter um token
				if (this.user == null) {
					await this.loginForm(APP_CONFIG.PUBLIC_USER.USERNAME, APP_CONFIG.PUBLIC_USER.PASS, true);
				}
			}
			return this.user;
		} catch (error) {
			// trata o erro para não travar a tela. Nesses casos será solicitado o login da fastget
			console.error(error);
			// Caso não tenha nenhum usuário carregado, loga no user público para obter um token
			if (this.user == null) {
				await this.loginForm(APP_CONFIG.PUBLIC_USER.USERNAME, APP_CONFIG.PUBLIC_USER.PASS, true);
			}
			return this.user;
		}
	}

	async loadLocalConfig(): Promise<void> {
		try {
			if (!this.localConfig) {
				this.localConfig = await this.storage.get("localConfig");
				if (!this.localConfig) {
					this.localConfig = new LocalConfig();
				}
			}
		} catch (error) {
			console.error(error);
			if (!this.localConfig) {
				this.localConfig = new LocalConfig();
			}
		}
	}

	async updateLocalConfig(): Promise<void> {
		await this.storage.set("localConfig", this.localConfig).catch((error) => console.log(error));
	}

	getEnvConfigs(): Promise<EnvConfig> {
		return new Promise((resolve, reject) => {
			if (!this.envConfig) {
				const urlRequest: string = "./assets/resources/custom-env.json";
				this.requestService.getData(urlRequest, true).then(async (response: Array<any>) => {
					this.envConfig = response.find((cfg) => cfg.hostname == window.location.hostname);
					resolve(this.envConfig);
				}, (erro: Error) => {
					console.log(erro);
					reject(erro);
				});
			} else {
				resolve(this.envConfig);
			}
		});
	}

	// Login
	// -------------------------------------

	async getRedirectFirebaseResult(): Promise<User> {
		try {
			if (isPlatform("cordova")) {
				return null;
			}
			const response: any = await firebase.auth().getRedirectResult();
			return await this.completeLoginWithFirebase(response);
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	loginWith(provider: LoginProvider, email?: string, password?: string): Promise<User> {
		switch (provider) {
			case LoginProvider.FACEBOOK:
				return this.loginFacebook();
			case LoginProvider.GOOGLE:
				return this.loginGoogle();
			case LoginProvider.APPLE:
				return this.loginApple();
			default:
				return this.loginForm(email, password, false);
		}
	}

	async loginGoogle(token?: string): Promise<User> {
		let accessToken: string = token;
		try {
			if (UtilHelper.isNativeApp()) {
				const response: any = await this.google.login({ });
				if (response && response.accessToken) {
					accessToken = response.accessToken;
				}
			} else if (!accessToken) {
				const provider: any = new firebase.auth.GoogleAuthProvider();
				provider.addScope('profile');
				provider.addScope('email');
				return await this.loginWithFirebase(provider);
			}
			console.log(accessToken);
			if (accessToken != null) {
				this.requestService.addParamsHeader('Authorization', accessToken);
				const tokenResponse: any = await this.requestService.getData(WEBSERVICE.LOGIN_GOOGLE);
				return await this.finishLogin(tokenResponse);
			} else {
				throw new ErrorApp(APP_CONFIG.ERRORS.TREATED, "Não foi possível realizar login com o Google");
			}
		} catch (error) {
			if (accessToken) {
				try {
					this.google.disconnect();
					firebase.auth().signOut();
				} catch (err) { }
			}
			console.log(error);
			throw error;
		}
	}

	async loginFacebook(token?: string): Promise<User> {
		let accessToken: string = token;
		try {
			if (UtilHelper.isNativeApp()) {
				const response: FacebookLoginResponse = await this.facebook.login(['public_profile', 'email']);
				if (response && response.authResponse && response.authResponse.accessToken) {
					accessToken = response.authResponse.accessToken;
				}
			} else if (!accessToken) {
				const provider: any = new firebase.auth.FacebookAuthProvider();
				provider.addScope('public_profile');
				provider.addScope('email');
				return await this.loginWithFirebase(provider);
			}
			console.log(accessToken);
			if (accessToken != null) {
				this.requestService.addParamsHeader('Authorization', accessToken);
				const tokenResponse: any = await this.requestService.getData(WEBSERVICE.LOGIN_FACEBOOK);
				return await this.finishLogin(tokenResponse);
			} else {
				throw new ErrorApp(APP_CONFIG.ERRORS.TREATED, "Não foi possível realizar login com o Facebook");
			}
		} catch (error) {
			if (accessToken) {
				try {
					this.facebook.logout();
					firebase.auth().signOut();
				} catch (err) { }
			}
			console.log(error);
			throw error;
		}
	}

	async loginApple(): Promise<User> {
		return new Promise((resolve, reject) => {
			const options: any = {
				requestedScopes: [
					AppleSignInScope.FULLNAME,
					AppleSignInScope.EMAIL
				]
			};
			(window as any).cordova.plugins.SignInWithApple.signin(options, (response: any) => {
				if (response != null) {
					console.log(JSON.stringify(response));
					this.requestService.addParamsHeader('Authorization', response.authorizationCode);
					this.requestService.addParamsHeader('ClientName', this.getFullNameFromAppleResponse(response));
					this.requestService.getData(WEBSERVICE.LOGIN_APPLE).then((tokenResponse: any) => {
						this.finishLogin(tokenResponse).then(() => {
							resolve(this.user);
						}, (error: ErrorApp) => {
							console.error(error);
							reject(error);
						});
					}, (error: ErrorApp) => {
						// TODO: logout Apple ???
						console.error(error);
						reject(error);
					});
				} else {
					reject(new ErrorApp(APP_CONFIG.ERRORS.TREATED, "Não foi possível realizar login com a Apple"));
				}
			}, (error) => {
				console.log(error);
				reject(error);
			});
		});
		// TODO:
		// let accessToken: string;
		// try {
		// 	if (UtilHelper.isNativeApp()) {
		// 		// TODO:
		// 	} else {
		// 		const provider = new firebase.auth.OAuthProvider('apple.com');
		// 		provider.addScope('name');
		// 		provider.addScope('email');
		// 		const response: any = await firebase.auth().signInWithPopup(provider);
		// 		console.log(response);
		// 		if (response) {
		// 			if (response.credential && response.credential.accessToken) {
		// 				accessToken = response.credential.accessToken;
		// 			}
		// 			if (response.user) {
		// 				this.requestService.addParamsHeader('ClientName', this.getFullNameFromAppleResponse(response));
		// 			}
		// 		}
		// 	}
		// 	console.log(accessToken);
		// 	if (accessToken != null) {
		// 		this.requestService.addParamsHeader('Authorization', accessToken);
		// 		const tokenResponse: any = await this.requestService.getData(WEBSERVICE.LOGIN_APPLE);
		// 		await this.finishLogin(tokenResponse);
		// 	} else {
		// 		throw new ErrorApi(APP_CONFIG.ERRORS.TREATED, "Não foi possível realizar login com a Apple");
		// 	}
		// } catch (error) {
		// 	if (accessToken) {
		// 		try {
		// 			this.facebook.logout();
		// 			firebase.auth().signOut();
		// 		} catch (err) { }
		// 	}
		// 	console.log(error);
		// 	throw error;
		// }
	}

	private async loginWithFirebase(provider: any): Promise<User> {
		// No desktop utiliza a forma de popup. No celular isso não funciona, ai utiliza o redirect
		if (isPlatform("desktop")) {
			const response: any = await firebase.auth().signInWithPopup(provider);
			return await this.completeLoginWithFirebase(response);
		} else {
			await firebase.auth().signInWithRedirect(provider);
		}
	}

	private async completeLoginWithFirebase(response: any): Promise<User> {
		if (response && response.credential && response.credential.accessToken) {
			switch (response.credential.providerId) {
				case "google.com":
					return await this.loginGoogle(response.credential.accessToken);

				case "facebook.com":
					return await this.loginFacebook(response.credential.accessToken);

				// case "apple.com":
				// 	return await this.loginGoogle(response.credential.accessToken);

				default:
					return null;
			}
		}
	}

	private getFullNameFromAppleResponse(response: any): string {
		/* {
			"email": "<email>",
			"authorizationCode": "<code>",
			"state": "",
			"identityToken": "<token>",
			"fullName": {
				"nickname": "",
				"phoneticRepresentation": {
				},
				"familyName": "",
				"namePrefix": "",
				"givenName": "",
				"nameSuffix": ""
			},
			"user": "<userid>"
		}*/
		let name: string = "";
		if (response && response.fullName) {
			if (response.fullName.givenName) {
				name += response.fullName.givenName + " ";
			}
			if (response.fullName.familyName) {
				name += response.fullName.familyName;
			}
		}
		return name.trim();
	}

	loginForm(user: string, password: string, isPublic: boolean): Promise<User> {
		return new Promise((resolve, reject) => {
			const params: any = {
				username: user.toLowerCase(),
				password: password,
				grant_type: "password",
				client_type: "customer"
			};
			this.requestService.addParamsHeader('Content-Type', "application/x-www-form-urlencoded");
			this.requestService.addParamsHeader('Authorization', "Basic ZnFtZ2NreHVwZGRwdDJrYW5mOmZhc3RnZXRBUEk=");
			this.requestService.postData(WEBSERVICE.AUTH_TOKEN, params).then((tokenResponse: any) => {
				if (tokenResponse && tokenResponse.access_token) {
					if (isPublic) {
						this.requestService.setAuthToken(tokenResponse.access_token);
						this.saveToken("token", tokenResponse.access_token);
						this.saveToken("refreshToken", tokenResponse.refresh_token);

						this.user = new User();
						this.user.isPublic = true;
						this.user.loginProvider = LoginProvider.OWN;
						this.localConfig.lastUserIsPublic = true;
						this.updateLocalConfig();
						resolve(this.user);
					} else {
						this.finishLogin(tokenResponse).then(() => {
							resolve(this.user);
						}, (error: ErrorApp) => {
							console.error(error);
							reject(error);
						});
					}
				} else {
					reject();
				}
			}, (error: ErrorApp) => {
				console.error(error);
				reject(error);
			});
		});
	}

	finishLogin(tokenResponse: any): Promise<User> {
		return new Promise(async (resolve, reject) => {
			if (tokenResponse && tokenResponse.access_token) {
				this.requestService.setAuthToken(tokenResponse.access_token);
				await this.saveToken("token", tokenResponse.access_token);
				await this.saveToken("refreshToken", tokenResponse.refresh_token);
				this.getUserInfo().then(() => {
					resolve(this.user);
				}, (error: ErrorApp) => {
					this.requestService.setAuthToken(null);
					reject(error);
				});
			} else {
				reject();
			}
		});
	}

	refreshToken(): Promise<void> {
		return new Promise(async (resolve, reject) => {
			const refreshToken: string = await this.getToken("refreshToken");
			if (refreshToken) {
				const params: any = {
					refresh_token: refreshToken,
					grant_type: "refresh_token",
					client_type: "customer"
				};
				this.requestService.addParamsHeader('Content-Type', "application/x-www-form-urlencoded");
				this.requestService.addParamsHeader('Authorization', "Basic ZnFtZ2NreHVwZGRwdDJrYW5mOmZhc3RnZXRBUEk=");
				this.requestService.postData(WEBSERVICE.AUTH_TOKEN, params).then((response: any) => {
					if (response && response.access_token) {
						this.requestService.setAuthToken(response.access_token);
						this.saveToken("token", response.access_token);
						this.saveToken("refreshToken", response.refresh_token);
						resolve();
					} else {
						reject();
					}
				}, (error: ErrorApp) => {
					console.error(error);
					reject(error);
				});
			} else {
				reject(new ErrorApp(401, "GENERAL.AUTHENTICATION_REQUIRED"));
			}
		});
	}

	trySilentLoginSocial(): Promise<void> {
		switch (this.user.loginProvider) {
			case LoginProvider.FACEBOOK:
				return this.trySilentFacebookLogin();
			case LoginProvider.GOOGLE:
				return this.trySilentGoogleLogin();
			default:
				// Apple não tem essa opção
				return new Promise((resolve, reject) => reject());
		}
	}

	trySilentGoogleLogin(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.google.trySilentLogin({ }).then((response: any) => {
				console.log("sucesso silent login google");
				if (response != null) {
					this.requestService.addParamsHeader('Authorization', response.accessToken);
					this.requestService.getData(WEBSERVICE.LOGIN_GOOGLE).then((tokenResponse: any) => {
						this.requestService.setAuthToken(tokenResponse.access_token);
						this.saveToken("token", tokenResponse.access_token);
						this.saveToken("refreshToken", tokenResponse.refresh_token);
						resolve();
					}, (error: ErrorApp) => {
						try {
							this.google.disconnect();
						} catch (error) { }
						console.error(error);
						reject(error);
					});
				} else {
					reject(new ErrorApp(APP_CONFIG.ERRORS.TREATED, "Não foi possível realizar login"));
				}
			}, (error: any) => {
				console.error(error);
				reject(error);
			});
		});
	}

	/**
	 * Facebook não tem um metodo para tentar fazer um silent login, porém foi criado esse método para apenas fazer a
	 *  chamada para obter um novo token, quando o atual expirar.
	 */
	trySilentFacebookLogin(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.facebook.login(['public_profile', 'email']).then((response: FacebookLoginResponse) => {
				console.log("sucesso silent login facebook");
				if (response != null) {
					this.requestService.addParamsHeader('Authorization', response.authResponse.accessToken);
					this.requestService.getData(WEBSERVICE.LOGIN_FACEBOOK).then((tokenResponse: any) => {
						this.requestService.setAuthToken(tokenResponse.access_token);
						this.saveToken("token", tokenResponse.access_token);
						this.saveToken("refreshToken", tokenResponse.refresh_token);
						resolve();
					}, (error: ErrorApp) => {
						try {
							this.facebook.logout();
						} catch (error) { }
						console.error(error);
						reject(error);
					});
				} else {
					reject(new ErrorApp(APP_CONFIG.ERRORS.TREATED, "Não foi possível realizar login com o Facebook"));
				}
			}, (error: any) => {
				console.error(error);
				reject(error);
			});
		});
	}

	logout(): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				switch (this.user.loginProvider) {
					case LoginProvider.FACEBOOK:
						this.facebook.logout().catch((erro) => { console.error(erro); });
						firebase.auth().signOut().catch((erro) => { console.error(erro); });
						break;

					case LoginProvider.GOOGLE:
						// Para renovar o token do google e conseguir fazer logot corretamente
						await this.google.trySilentLogin({ }).catch((error) => console.error(error));

						this.google.disconnect().catch((erro) => { console.error(erro); });
						firebase.auth().signOut().catch((erro) => { console.error(erro); });
						break;

					case LoginProvider.APPLE:
						// Apple não tem essa opção
						break;

					default:
						break;
				}
				this.logoutFastget().then(() => {
					this.eventService.publish(AppEvents.LOGOUT);
					resolve();
				});
			} catch (error) {
				console.error(error);
				this.removeUser();
				this.eventService.publish(AppEvents.LOGOUT);
				resolve();
			}
		});
	}

	private logoutFastget(): Promise<void> {
		return new Promise((resolve, reject) => {
			this.requestService.addParamsHeader('Authorization', "Basic ZnFtZ2NreHVwZGRwdDJrYW5mOmZhc3RnZXRBUEk=");
			this.requestService.addParamsHeader('RevokeAccessToken', this.requestService.getAuthToken());
			this.requestService.deleteData(WEBSERVICE.AUTH_TOKEN).then((response: any) => {
				this.removeUser();
				resolve();
			}, (error: ErrorApp) => {
				this.removeUser();
				resolve();
			});
		});
	}

	saveUserData(user: User): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				user.cpf = UtilHelper.unmask(user.cpf);
				user.phone = UtilHelper.unmask(user.phone);
				user.username = user.username.toLowerCase();
				if (user.id) {
					await this.requestService.putData(WEBSERVICE.USER_CLIENT, user.getJsonToChangeData());
				} else {
					await this.requestService.postData(WEBSERVICE.USER_CLIENT, user.getJsonToRegister());
				}
				resolve();
			} catch (error) {
				console.error(error);
				reject(error);
			}
		});
	}

	updatePassword(id: number, oldPassword: string, newPassword: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const data: any = {
				oldPassword: oldPassword,
				newPassword: newPassword,
				id: id
			};
			this.requestService.postData(WEBSERVICE.UPDATE_PASSWORD_FROM_VIEW, data).then(() => {
				resolve();
			}, (error: Error) => {
				console.error(error);
				reject(error);
			});
		});
	}

	sendForgotPasswordEmail(email: string): Promise<any> {
		return new Promise((resolve, reject) => {
			const params: any = {
				email: email,
				isCustomer: true
			};
			this.requestService.addParamsHeader('Content-Type', "application/x-www-form-urlencoded");
			this.requestService.postData(WEBSERVICE.FORGOT_PASSWORD, params).then((response: any) => {
				console.log(response);
				resolve(response);
			}, (error: Error) => {
				console.log(error);
				reject(error);
			});
		});
	}

	verifyPasswordToken(token: string): Promise<any> {
		return new Promise((resolve, reject) => {
			const urlRequest: string = this.requestService.buildUrlPathParams({ token: token }, WEBSERVICE.VERIFY_UPDATE_PASSWORD_LINK);
			this.requestService.getData(urlRequest).then((response: any) => {
				console.log(response);
				resolve(response);
			}, (error: Error) => {
				console.log(error);
				reject(error);
			});
		});
	}

	activeAccount(token: string): Promise<any> {
		return new Promise((resolve, reject) => {
			const params: any = {
				token: token
			};
			this.requestService.addParamsHeader('Content-Type', "application/x-www-form-urlencoded");
			this.requestService.postData(WEBSERVICE.ACTIVE_ACCOUNT, params).then((response: any) => {
				console.log(response);
				resolve(response);
			}, (error: Error) => {
				console.log(error);
				reject(error);
			});
		});
	}

	savePassword(type: string, token: string, newPassword: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const data: any = {
				emailToken: token,
				password: newPassword
			};
			let urlRequest: string;
			switch (type) {
				case "reset":
					urlRequest = WEBSERVICE.UPDATE_PASSWORD;
					break;

				default: // new
					urlRequest = WEBSERVICE.CREATE_PASSWORD;
					break;
			}
			this.requestService.addParamsHeader('Content-Type', "application/x-www-form-urlencoded");
			this.requestService.postData(urlRequest, data).then(() => {
				resolve();
			}, (error: Error) => {
				console.error(error);
				reject(error);
			});
		});
	}

	sendSMS(phoneNumber: string, userId: number): Promise<any> {
		return new Promise((resolve, reject) => {
			const params: any = {
				phoneNumber: UtilHelper.unmask(phoneNumber),
				userId: userId
			};
			const urlRequest: string = this.requestService.buildUrlPathParams(params, WEBSERVICE.SEND_SMS_VALIDATE_PHONE);
			this.requestService.postData(urlRequest).then((response: any) => {
				console.log(response);
				resolve(response);
			}, (error: Error) => {
				console.log(error);
				reject(error);
			});
		});
	}

	validatePhone(code: string, userId: number): Promise<any> {
		return new Promise((resolve, reject) => {
			const params: any = {
				userId: userId,
				code: UtilHelper.unmask(code)
			};
			const urlRequest: string = this.requestService.buildUrlPathParams(params, WEBSERVICE.VALIDATE_PHONE);
			this.requestService.postData(urlRequest).then((response: any) => {
				console.log(response);
				resolve(response);
			}, (error: Error) => {
				console.log(error);
				reject(error);
			});
		});
	}

	getCountries(): Promise<any> {
		return new Promise((resolve, reject) => {
			this.requestService.getData("assets/resources/country-codes.json", true).then((response: any) => {
				resolve(response || []);
			}, (error: Error) => {
				console.log(error);
				reject(error);
			});
		});
	}

	getDefaultCountry(): Country {
		return new Country({ name: "Brazil", flag: "🇧🇷", code: "BR", dial_code: "+55" });
	}

	showModalMaintenance(): void {
		this.eventService.publish(AppEvents.SHOW_MAINTENANCE);
	}
}
