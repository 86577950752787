export class Filter {
	distance: number;
	count: number;

	constructor(obj: any = { }) {
		Object.assign(this, obj);

		if (!this.distance) {
			this.distance = 20;
		}
	}
}
